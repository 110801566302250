import React, { useEffect, useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'
import { PageContextPropsType } from '../../i18n/i18n'
import LayoutBlog from '../components/LayoutBlog'
import BlogAside from '../components/BlogAside'
import HeroSubPage from '../components/HeroSubPage'
import SEO from '../components/SEO'
import { BlogArticleDatas, BlogTagData, BlogArticleDataList } from '../types/blog'
import * as BlogTagPageStyles from '../styles/BlogTagPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames/bind'
import BlogPagination from '../components/BlogPagination'
import { useLocation } from '@reach/router'
const cx = classNames.bind(BlogTagPageStyles)

type BlogTagProps = {
  data: { newtBlogTag: BlogTagData; allNewtBlogArticle: BlogArticleDataList }
} & PageContextPropsType

export default function BlogTag({ data, pageContext }: BlogTagProps) {
  const FormatDate = useCallback((date: string) => {
    const Date = DateTime.fromISO(date).setZone('Asia/Tokyo').toFormat('yyyy.MM.dd')
    return Date
  }, [])
  const slug = `/blog/tag/${data.newtBlogTag.slug}/`
  const router = useLocation()
  const queryNumberReplace = Number(router.search.replace(/[^\d]/g, '') || 1)
  const queryNumber = useMemo(() => queryNumberReplace, [router])

  const ARTICLE_PER_PAGE = 10
  const currentPageIndex = queryNumber - 1
  const start = currentPageIndex * ARTICLE_PER_PAGE
  const end = start + ARTICLE_PER_PAGE
  const allNewtBlogArticleSlice = data.allNewtBlogArticle.edges.slice(start, end)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [router])
  return (
    <LayoutBlog pageContext={pageContext}>
      <SEO
        title={data.newtBlogTag.name + 'タグ | KARTE Blocks Blog'}
        description="KARTE Blocks Blogは、サイト制作・運用・成果改善のコツとKARTE Blocksの最新事例を発信しています。"
        path={slug}
      />
      <HeroSubPage />
      <div className={cx('Container')}>
        <div className={cx('Container_Inner')}>
          <div className={cx('Container_Head')}>
            <FontAwesomeIcon className={cx('Container_Head_Icon')} icon={faTag}></FontAwesomeIcon>
            <h1 className={cx('Container_Head_Title')}>{data.newtBlogTag.name}</h1>
          </div>
          <p className={cx('Container_Description')}>{data.newtBlogTag.description}</p>
          <div className={cx('Container_Article')}>
            {allNewtBlogArticleSlice.map((item: BlogArticleDatas) => (
              <a href={`/blog/` + item.node.slug + `/`} className={cx('Container_ArticleItem')} key={item.node._id}>
                <img className={cx('Container_ArticleItem_Image')} src={item.node.thumbnail.src} width="500" />
                <div className={cx('Container_ArticleItem_Content')}>
                  <p className={cx('Container_ArticleItem_Date')}>{FormatDate(item.node.meta.metaPublishDate)}</p>
                  <p className={cx('Container_ArticleItem_Title')}>{item.node.title}</p>
                  <div className={cx('Container_ArticleItem_Tags')}>
                    {item.node.tags.map((tag, index) => (
                      <div key={index} className={cx('Container_ArticleItem_Tag')}>
                        <p>#{tag.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </a>
            ))}
            {data.allNewtBlogArticle.totalCount > 10 && (
              <BlogPagination
                page="tag"
                slug={data.newtBlogTag.slug}
                searchNumber={queryNumber}
                totalCount={data.allNewtBlogArticle.totalCount}
              />
            )}
          </div>
        </div>
        <BlogAside />
      </div>
    </LayoutBlog>
  )
}

export const query = graphql`
  query ($_id: String!) {
    allNewtBlogTag {
      edges {
        node {
          trend
          name
          slug
        }
      }
    }
    allNewtBlogArticle(filter: { tags: { elemMatch: { _id: { eq: $_id } } } }) {
      totalCount
      edges {
        node {
          _id
          title
          slug
          thumbnail {
            _id
            src
          }
          meta {
            metaPublishDate
          }
          tags {
            _id
            name
            slug
          }
        }
      }
    }
    newtBlogTag(_id: { eq: $_id }) {
      name
      slug
      trend
      description
    }
  }
`
